import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import '../../../styles/main.scss';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import _ from 'lodash';
import { IconButton, Modal, Paper } from '@material-ui/core';
import { Close, Menu } from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));



function Nav({ Logo, Label, ...props }) {

  const classes = useStyles();
  const [showMobileMenu, toggleMobileMenu] = React.useState(false);
  const isMobile = window.innerWidth < 479;

  const onNavLinkClick = (title, sectionLink) => () => {

    // if (sectionLink) {
    // }
  }


  return (
    <div id={props.id} className={classnames(classes.root, props.className)}>
      <AppBar position="fixed" style={{ background: 'transparent' }} elevation={0}>
        <Toolbar style={{ background: 'transparent' }}>
          <Logo />
          {
            Label &&
            <div className="nav-bar flex-middle">
              <Label />
            </div>
          }
          {
            isMobile ?
              <IconButton onClick={() => toggleMobileMenu(!showMobileMenu)}>
                <Menu />
              </IconButton> :
              (
                <div className="flex-row nav-link-container">
                  {
                    _.map(props.titles, (title, index) => {
                      return (
                        <a
                          key={index}
                          className="nav-link"
                          href={title.sectionLink ? `/#${title.sectionLink}` : title.link}
                        >
                          {title.name}
                        </a>
                      )
                    })
                  }
                </div>
              )
          }

        </Toolbar>
      </AppBar>
      <Modal
        open={showMobileMenu}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showMobileMenu}>
          <Paper className="nav-mobile-menu-paper">
            <div>
              <div className="nav-mobile-close">
                <IconButton aria-label="Close">
                  <Close onClick={() => toggleMobileMenu(false)} />
                </IconButton>
              </div>
              <div>
                {
                  _.map(props.titles, (title, index) => {
                    return (
                      <a
                        href={title.sectionLink ? `#${title.sectionLink}` : title.link}
                        className="nav-link"
                        key={index}
                      >
                        <h1
                          className="simple-nav margin-t-b-20 text-center"
                          onClick={() => {
                            onNavLinkClick(title)()
                            toggleMobileMenu(false)
                          }}>
                          {title.name}
                        </h1>
                      </a>
                    )
                  })
                }
              </div>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}


export default withRouter(Nav)


// <AccountCircleIcon fontSize="large" color='action' onClick = { () => props.history.push('/account/signin')} style={{paddingRight:5}}/>
//  <MenuIcon fontSize="large" color='action'/>
