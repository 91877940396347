import request from './request';

const sendMessage = async (payload) => {

    const res = await request.post('https://visitorupdates-4726.twil.io', {
        To: `+91${payload.to}`,
        Body: `Greetings from eyebrowraise studio! ${payload.name} (phone: ${payload.phone}) is enquiring about details. Message: ${payload.message}. Please reach out. Thanks.`,
    }, {
        absoluteUrl: true
    })
}

export default sendMessage;