import superagent from 'superagent';
import config from '../config';
import _ from 'lodash';

const responseBody = res => {
    return res.body;
}

const responseHandler = (options) => {
    return options.responseHandler || responseBody;
}

const getUrl = url => `${config.api.baseUrl}/${url}`;

const request = {
    get: (url, data, options = {}) => {
        const req = superagent
        .get(options.absoluteUrl ? url : getUrl(url))
        
        if (options.headers) {
            _.forIn(options.headers, (value, key) => {
                req.set(key, value);
            })
        }
        return req.then(responseHandler(options));
    },
    post: (url, data, options = {}) => {
        return (
            superagent
            .post(options.absoluteUrl ? url : getUrl(url))
            .send(data)
            .then(responseHandler(options))
        )
    },
    patch: (url, data, options = {}) => {
        return (
            superagent
            .patch(getUrl(url))
            .send(data)
            .then(responseHandler(options))
        )
    },
};

export default request;
