import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { withFormsy } from 'formsy-react';
import _ from 'lodash'
// import firebase from '@firebaseConnect';
// import { getStorage, ref , uploadBytes } from "firebase";

class HTMLInput extends React.Component {

  constructor(props) {
    super(props);
    this.props.setValue(props.defaultValue)
  }
  handleEditorChange = (content, editor) => {
    this.props.setValue(content || null)
  }

  // getFirebase = () => firebase.shop

  onImageUpload = async (blobInfo, success, error) => {
    const uploadDestinationFolder = 'description-image'
    const details = await this.getFirebase()
    .storage()
    .ref(uploadDestinationFolder)
    .child(blobInfo.filename())
    const snapshot = await details.put(blobInfo.blob())
    const url = await details.getDownloadURL()
    success(url)
  }


  render() {
    return (
      <div style={{ margin: '10px 0px' }}>
        <Editor
          initialValue={this.props.defaultValue}
          apiKey="gbut2zgtq1521o52fv4ovz5j5zd11ec9abaapvckxagugvt5"
          init={{
            height: 500,
            menubar: false,
            images_upload_handler: this.onImageUpload,
            automatic_uploads: true,
            file_picker_types: 'image',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code wordcount autoresize'
            ],
            toolbar:
              'undo redo | image | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat ',
          }}
          onEditorChange={this.handleEditorChange}
        />
      </div>
    );
  }
}

export default withFormsy(HTMLInput);
